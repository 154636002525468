import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

const SUFFIX = 'Ideals';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  readonly #title = inject(Title);
  readonly #translateService = inject(TranslateService);

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (title) {
      this.#translateService.stream(title).pipe(filter(Boolean)).subscribe((translatedTitle) => {
        this.#title.setTitle(translatedTitle.includes(SUFFIX) 
          ? translatedTitle 
          : `${translatedTitle} | ${SUFFIX}`);
      });
    }
  }
}
