import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HelpModalOldComponent} from '../help-modal-old/help-modal-old.component';

@Component({
  selector: 'ideals-help-button-old',
  templateUrl: './help-button-old.component.html',
  styleUrls: ['./help-button-old.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpButtonOldComponent implements OnInit {
  @Input()
  public readonly unauthorizedUser: boolean;

  @Input()
  public readonly isContactsActive: boolean;

  @Input()
  public readonly isContactsHidden: boolean;

  @Input()
  public readonly isPhoneSupportHidden: boolean;

  @Input()
  public readonly isDealOwner: boolean;

  public buttonText: string;
  public iconClass: string;

  constructor(
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {
  }

  public ngOnInit(): void {
    this.buttonText = this.isContactsActive ? 'common.BTN.contacts' : 'common.BTN.help';
    this.iconClass = this.isContactsActive ? 'icons-phone' : 'icons-question';
  }

  public openHelpModal(event: MouseEvent): void {
    event?.preventDefault();

    this.dialog.open(HelpModalOldComponent, {
      panelClass: 'help-modal-old',
      width: '1046px',
      height: '700px',
      data: {
        unauthorizedUser: this.unauthorizedUser,
        isDealOwner: this.isDealOwner,
        isContactsActive: this.isContactsActive,
        isContactsHidden: this.isContactsHidden,
        isPhoneSupportHidden: this.isPhoneSupportHidden,
        close: this.closeDialog.bind(this),
      }
    });
  }

  public closeDialog(): void {
    const element = this.document.querySelectorAll('.cdk-global-overlay-wrapper');
    element[0].parentNode.removeChild(element[0]);
  }
}
